<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid box-component">
            <div class="box-body box-details-header">
              <div class="details-header">
                <div class="details-header--details">
                  <h2>Dane jednostki policji</h2>
                  <ul class="list-unstyled">
                    <li><label>Nazwa:</label> {{ police.label }}</li>
                    <li><label>Województwo:</label> {{ voivodeshipName }}</li>
                    <li><label>Miejscowość:</label> {{ police.city }}</li>
                    <li><label>Kod pocztowy:</label> {{ police.postcode }}</li>
                    <li><label>Adres:</label> {{ police.street }}</li>
                    <li><label>e-mail:</label> {{ police.email }}</li>
                    <li><label>Telefon:</label> {{ police.phone }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <policeUnit-edit-modal
      :show="policeUnitEditVisible"
      :form-data="police"
      @close="policeUnitEditVisible = false"
      @success="policeEdited"
    />
  </div>
</template>

<script>
import api from '../../../../../api'
import DynamicBreadcrumb from '../../../share/mixins/DynamicBreadcrumb'
import PoliceUnitEditModal from './modal/PoliceUnitEdit'
import Page from '../../../../share/content/Page'

export default {
  components: {
    PoliceUnitEditModal
  },
  mixins: [
    DynamicBreadcrumb,
    Page
  ],
  data () {
    return {
      events: {
        editPoliceUnit: 'core:policeUnit:editPoliceUnit'
      },
      headerTitle: { title: 'Jednostka policji', description: 'Szczegóły' },
      policeUnitEditVisible: false,
      actionEditName: 'policeUnit_edit',
      urlActions: {
        getContent: { method: 'get', url: `/police/${this.$route.params.id}` }
      },
      police: {
        label: '',
        voivodeship: '',
        city: '',
        postcode: '',
        street: '',
        email: '',
        phone: ''
      }
    }
  },
  computed: {
    voivodeshipName () {
      if (this.$store.state.base.voivodeships.length !== 0 && this.police.voivodeship !== '') {
        return this.$store.state.base.voivodeships.filter(v => v.value.toString() === this.police.voivodeship.toString())[0].label
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$events.on(this.events.editPoliceUnit, this.editPoliceUnit)

    this.$nextTick(() => {
      this.loadContent()
    })
  },
  methods: {
    editPoliceUnit () {
      this.policeUnitEditVisible = true
    },
    loadContent () {
      this.toggleLoading()

      api.request('core', this.urlActions.getContent.method, this.urlActions.getContent.url)
        .then((response) => {
          this.toggleLoading()
          let data = response.data.police
          this.police = data
          this.police.voivodeship = data.voivodeship.id

          // dynamic breadcrumb labels
          this.addDynamicBreadcrumbLabel(this.$route.name, this.police.label)

          this.dataLoaded = true
        })
        .catch(() => {
          this.toggleLoading()
        })
    },
    policeEdited (police) {
      this.police = police
    }
  }
}
</script>

<style scoped>
    .label {
        display: inline-block;
        margin-bottom: 0.3em;
        vertical-align: middle;
    }
</style>
